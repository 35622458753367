<template>
    <div class="kteventsection2_container">
        <p class="kteventsection2_text1">단 <span class="kteventsection2_text1_span">7일</span>!</p>
        <p class="kteventsection2_text2">
            KT닷컴에서 <span class="kteventsection2_text2_span">사전 예약</span>하고 <br />
            풍성한 혜택 받아가세요!
        </p>
        <p class="kteventsection2_text3">
            이벤트 기간 : 7/12(금) ~ 7/18(목)
        </p>

        <img
            class="ktevnet_section2_startimg"
            src="/media/img/collaboration/ktevent_section2_start.png"
            alt="ktevent_section2_start.png"
        />
        <img
            class="ktevnet_section2_giftimg"
            src="/media/img/collaboration/ktevent_section2_gift.png"
            alt="ktevent_section2_gift.png"
        />
    </div>
</template>
<script>
export default {
  name: 'KtEventSection2',

  data () {
    return {};
  },

  mounted () {},

  methods: {}
};
</script>
<style src="@/assets/css/collaboration/collaborfont.css"></style>
<style scoped src="@/assets/css/collaboration/kteventsection2.css"></style>
