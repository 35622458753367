import { render, staticRenderFns } from "./KtEventSection2.vue?vue&type=template&id=ac0fad18&scoped=true"
import script from "./KtEventSection2.vue?vue&type=script&lang=js"
export * from "./KtEventSection2.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/collaboration/collaborfont.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@/assets/css/collaboration/kteventsection2.css?vue&type=style&index=1&id=ac0fad18&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac0fad18",
  null
  
)

export default component.exports